<script setup>
import { reactive, inject, ref, onMounted, watchEffect } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { useNavStore } from './../stores/nav'
import { useServiceStore } from './../stores/service'
import Loading from './../components/Loading.vue'
import { getCustomerByMail, updateOrCreateCustomer, createQuote } from './../services/woo'
// import router from './../router';

const quotationStore = useQuotationStore();
const serviceStore = useServiceStore();
const navStore = useNavStore();

const { emitCustomEvent } = inject('cancelBomb')
const { config } = inject('config')

const state = reactive({
  step : 'email',
  debug : '',
  firstname : '',
  mail: false,
  mailError: false,
  error : false,
  required: false,
  loading : {
    message : 'Chargement en cours, merci de patienter',
    status : true,
  }
});

const email = ref('');
const customerRefs = ref({
  gender: 'M',
  first_name: '',
  last_name: '',
  billing: {
    phone: '',
    company: '',
    address_1: '',
    postcode: '',
    city: ''
  }
});     

quotationStore.$setPoseur('non');
quotationStore.$setDelai('1-mois');
quotationStore.$setRappel('non');

onMounted(() => {
  watchEffect(async() => {
    if (email.value != undefined && email.value.trim() != '') {
      state.mail = true;
      email.value = email.value.trim();
    } else {
      state.mail = false;
    }
  })
})

const validate = () => {
  navStore.$pause();
  state.step = '';
  state.loading = {
    message : 'Recherche de votre email en cours',
    status : true
  };
  getCustomerByMail(
      email.value.trim(), 
      config.wooId, 
      config.wooPass  
  ).then((res) => {
    //console.log('User = ', res);
    if (res.data && res.data.status == 400) {
      // email error
      state.step = '';
      state.required = false;
      state.mailError = true;
      state.loading = {
        message : res.data.params.email,
        status : false
      };
      state.error = true;
    } else if (res.length == 0) { 
      // new customer
      quotationStore.$setEmail(email.value);
      state.step = 'user';
      state.error = false;
    } else {
      // user already registered
      quotationStore.$setEmail(email.value);
      quotationStore.$setCustomer(res[0]);
      
      let gender = quotationStore._customer['gender'];
      if (gender == undefined) gender = 'M';
      if (quotationStore._customer['first_name'].substring(0,2) == 'M ')
      {
        quotationStore._customer['first_name'] = quotationStore._customer['first_name'].substring(2, quotationStore._customer['first_name'].length);
        gender = 'M';
      }
      if (quotationStore._customer['first_name'].substring(0,4) == 'Mme ')
      {
        quotationStore._customer['first_name'] = quotationStore._customer['first_name'].substring(4, quotationStore._customer['first_name'].length);
        gender = 'Mme';
      }
      
      customerRefs.value = {
        'gender' : gender,
        'first_name' : quotationStore._customer['first_name'],
        'last_name' : quotationStore._customer['last_name'],
        'billing' : {
          'phone' : quotationStore._customer['billing']['phone'],
          'company' : quotationStore._customer['billing']['company'],
          'address_1' : quotationStore._customer['billing']['address_1'],
          'postcode' : quotationStore._customer['billing']['postcode'],
          'city' : quotationStore._customer['billing']['city']
        }
      };
      
      state.step = 'user';
      state.error = false;
    }
    serviceStore._inProgress = true; // Block prev step
    navStore.$resume();
    navStore.$restart(true);
  });
}

const goToDevis = () => {
  if (quotationStore._cgv == false) {
    state.step = '';
    state.required = true;
    state.loading = {
      message : 'Merci d\'accepter les conditions générales de vente',
      status : false
    };
    state.error = true;
  } else {
    finish();
  }
}

const finish = () => {
  if (customerRefs.value.gender != '' 
    && customerRefs.value.first_name != '' 
    && customerRefs.value.last_name != '' 
    && customerRefs.value.billing.phone != '' 
    && customerRefs.value.billing.address_1 != '' 
    && customerRefs.value.billing.postcode != '' 
    && customerRefs.value.billing.city != '') {
    
    navStore.$pause();
    
    state.step = '';
    state.loading = {
      message : 'Envoi de votre devis, merci de patienter',
      status : true
    };
    
    const customer = [];
    customer['id'] = quotationStore._customer['id'];
    customer['gender'] = customerRefs.value.gender;
    customer['email'] = quotationStore._email;
    customer['first_name'] = customerRefs.value.first_name;
    customer['last_name'] = customerRefs.value.last_name;
    customer['billing'] = [];
    customer['billing']['phone'] = customerRefs.value.billing.phone;
    customer['billing']['company'] = customerRefs.value.billing.company;
    customer['billing']['address_1'] = customerRefs.value.billing.address_1;
    customer['billing']['postcode'] = customerRefs.value.billing.postcode;
    customer['billing']['city'] = customerRefs.value.billing.city;
    
    quotationStore.$setCustomer(customer);
    
    const customerDb = {
      'id' : quotationStore._customer['id'],
      'email' : quotationStore._email,
      'username' : quotationStore._email,
      'password' : quotationStore._email,
      'first_name' : quotationStore._customer['gender'] + ' ' + quotationStore._customer['first_name'],
      'last_name' : quotationStore._customer['last_name'],
      'billing' : {
        'first_name' : quotationStore._customer['first_name'],
        'last_name' : quotationStore._customer['last_name'],
        'phone' : quotationStore._customer['billing']['phone'],
        'company' : quotationStore._customer['billing']['company'],
        'address_1' : quotationStore._customer['billing']['address_1'],
        'postcode' : quotationStore._customer['billing']['postcode'],
        'city' : quotationStore._customer['billing']['city']
      }
    };
    //console.log('Id woo = ',quotationStore._customer['id'], customerDb);-->
    updateOrCreateCustomer(
        customerDb, 
        config.wooId, 
        config.wooPass,
        (quotationStore._customer['id'] > 0) ? 'PUT' : 'POST'
    ).then((res) => {
      if (res.id) {
        quotationStore.$setCustomer(res);
        
        // Création du devis
        let productsQuote = quotationStore._essenceId + ',' + quotationStore._structureId + ',' + quotationStore._fixationId + ',';
        (quotationStore._accessoires).forEach((v, k) => {
          productsQuote += k + ',';
        });
        if (quotationStore._accessoires.length == 0) {
          productsQuote += '-1,';
        }

        const quoteJson = {
          'action': 'createQuote',
          'id' : quotationStore._customer['id'],
          'surface': quotationStore._surface,
          'type': quotationStore._type,
          'products' : productsQuote,
          'poseur' : quotationStore._poseur,
          'delai' : quotationStore._delai,
          'rappel' : quotationStore._rappel,
          'message' : quotationStore._message
        };

        createQuote(quoteJson).then((res) => {
          if (res.status == 1) {
            state.step = 'sent';
            state.loading.status = false;
            navStore.$resume();
            serviceStore._inProgress = false;
          } else {
            state.step = 'error';
            state.loading.status = false;
            navStore.$resume();
            serviceStore._inProgress = false;
          }
        });
      } else {
        state.step = '';
        state.loading = {
          message : 'Une erreur est survenue lors de la sauvegarde de vos informations',
          status : false
        };
        state.error = true;
      }
    });
    
  } else {
    state.step = '';
    state.required = true;
    state.loading = {
      message : 'Merci de compléter tous les champs obligatoires (*)',
      status : false
    };
    state.error = true;
  }
}

const back = (step) => {
  state.step = step;
  state.error = false;
}

const retry = () => {
  state.step = 'email';
  state.error = false;
  state.required = false;
  state.mailError = false;
  state.loading = {
    message : 'Chargement en cours, merci de patienter',
    status : true
  };
  serviceStore._inProgress = false; // Unblock prev step
  navStore.$restart(false);
}
</script>

<template>
  <div class="page-content">
    <div class="page-title">
      <h1>Nous préparons votre devis</h1>
    </div>
    
    <div @click="emitCustomEvent(this)" v-if="state.step == 'email'" class="userForm">
      <ui-grid>
        <!--<ui-grid-cell columns="2" class="separator"></ui-grid-cell>//-->
        <ui-grid-cell columns="12">
          <div>
            <label for="email" class="labelEmail">Pour continuer saisissez votre e-mail :</label>
            <ui-textfield outlined name="email" type="string" v-model.lazy="email" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
              Votre email
              <template #before>
                <ui-textfield-icon unclickable>email</ui-textfield-icon>
              </template>
            </ui-textfield>
          </div>
        </ui-grid-cell>
        <!--<ui-grid-cell columns="2" class="separator"></ui-grid-cell>//-->
      </ui-grid>

      <div class="nav">
        <button class="mdc-button mdc-button--raised mdc-button--primary" @click="navStore.$prev">Précédent</button>
        <button class="mdc-button mdc-button--raised mdc-button--primary" :class="{ 'disabled': state.mail == false }" @click="validate()">Suivant</button>
      </div>
    </div>
    <div v-else-if="state.step == 'user'"  @click="emitCustomEvent(this)" class="userForm">
      <ui-grid>
        <!--<ui-grid-cell columns="2" class="separator"></ui-grid-cell>//-->
        <ui-grid-cell columns="12">
          <div>
           <ui-form-field>
              <ui-radio v-model.lazy="customerRefs.gender" input-id="male" value="M"></ui-radio>
              <label for="male" :class="{'selected': customerRefs.gender == 'M'}">M</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="customerRefs.gender" input-id="female" value="Mme"></ui-radio>
              <label for="female" :class="{'selected': customerRefs.gender == 'Mme'}">Mme</label>
            </ui-form-field>
             <!--<ui-form-field>
              <ui-textfield outlined required name="firstname" type="string" v-model.lazy="firstname">
                Prénom
                <template #before>
                  <ui-textfield-icon>person</ui-textfield-icon>
                </template>
              </ui-textfield>
            </ui-form-field>
            <ui-form-field>
              <ui-textfield outlined required name="lastname" type="string" v-model.lazy="lastname">
                Nom
                <template #before>
                  <ui-textfield-icon>person</ui-textfield-icon>
                </template>
              </ui-textfield>
            </ui-form-field>//-->
          </div>
          <div class="col-2">
            <ui-textfield outlined required name="firstname" type="string" v-model.lazy="customerRefs.first_name" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
               Prénom
               <template #before>
                 <ui-textfield-icon unclickable>person</ui-textfield-icon>
               </template>
             </ui-textfield>
            <ui-textfield outlined required name="lastname" type="string" v-model.lazy="customerRefs.last_name" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
                Nom
                <template #before>
                  <ui-textfield-icon unclickable>person</ui-textfield-icon>
                </template>
              </ui-textfield>
          </div>
          <div class="col-2">
            <ui-textfield fullwidth outlined required name="phone" type="string" v-model.lazy="customerRefs.billing.phone" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
                Téléphone
                <template #before>
                  <ui-textfield-icon unclickable>contact_phone</ui-textfield-icon>
                </template>
              </ui-textfield>
              <ui-textfield fullwidth outlined name="company" type="string" v-model.lazy="customerRefs.billing.company" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
                Société
                <template #before>
                  <ui-textfield-icon unclickable>business</ui-textfield-icon>
                </template>
              </ui-textfield>
            </div>
            <div class="col-2">
              <ui-textfield fullwidth outlined required name="postcode" type="number" v-model.lazy="customerRefs.billing.postcode" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
                Code postal
              </ui-textfield>
              <ui-textfield fullwidth outlined required name="city" type="string" v-model.lazy="customerRefs.billing.city" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
                Ville
              </ui-textfield>
            </div>
          <div>
            <ui-textfield fullwidth outlined required name="address" type="string" v-model.lazy="customerRefs.billing.address_1" @click="emitCustomEvent(this)" v-on:keyup="emitCustomEvent(this)">
              Adresse
              <template #before>
                <ui-textfield-icon unclickable>home</ui-textfield-icon>
              </template>
            </ui-textfield>
          </div>
          <div>
            <p>Voulez-vous une mise en relation avec un poseur ? <span class="required">*</span></p>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._poseur" input-id="poseur-yes" value="oui"></ui-radio>
              <label for="poseur-yes" :class="{'selected': quotationStore._poseur == 'oui'}">Oui</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._poseur" input-id="poseur-no" value="non"></ui-radio>
              <label for="poseur-no" :class="{'selected': quotationStore._poseur == 'non'}">Non</label>
            </ui-form-field>
          </div>
          <div>
            <p>Quel délai de réalisation souhaitez-vous ? <span class="required">*</span></p>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._delai" input-id="maintenant" value="maintenant"></ui-radio>
              <label for="maintenant" :class="{'selected': quotationStore._delai == 'maintenant'}">Maintenant</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._delai" input-id="1-mois" value="1-mois"></ui-radio>
              <label for="1-mois" :class="{'selected': quotationStore._delai == '1-mois'}">1 mois</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._delai" input-id="3-mois" value="3-mois"></ui-radio>
              <label for="3-mois" :class="{'selected': quotationStore._delai == '3-mois'}">3 mois</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._delai" input-id="6-mois" value="6-mois"></ui-radio>
              <label for="6-mois" :class="{'selected': quotationStore._delai == '6-mois'}">6 mois</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._delai" input-id="1-an" value="1-an"></ui-radio>
              <label for="1-an" :class="{'selected': quotationStore._delai == '1-an'}">1 an</label>
            </ui-form-field>
          </div>
          <div>
            <p>Souhaitez-vous être rappelé par un conseiller ? <span class="required">*</span></p>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._rappel" input-id="rappel-yes" value="oui"></ui-radio>
              <label for="rappel-yes" :class="{'selected': quotationStore._rappel == 'oui'}">Oui</label>
            </ui-form-field>
            <ui-form-field>
              <ui-radio v-model.lazy="quotationStore._rappel" input-id="rappel-no" value="non"></ui-radio>
              <label for="rappel-no" :class="{'selected': quotationStore._rappel == 'non'}">Non</label>
            </ui-form-field>
          </div>
          <div>
            <p>Vous pouvez également nous laisser un message :</p>
            <textarea v-model.lazy="quotationStore._message"></textarea>
          </div>
          <div class="checkbox-field checkbox-field-cgv">
            <ui-form-field>
              <ui-checkbox v-model.lazy="quotationStore._cgv" input-id="cgv" value="true"></ui-checkbox>
              <label for="cgv" class="cgv-label">J'accepte les <a href="https://www.deckibois.fr/contact/conditions-generales-de-vente/" target="_blank">conditions générales de vente</a> et la <a href="https://www.deckibois.fr/contact/politique-de-confidentialite/" target="_blank">politique de confidentialité</a> de Deckibois.</label>
            </ui-form-field>
          </div>
        </ui-grid-cell>
        <!--<ui-grid-cell columns="2" class="separator"></ui-grid-cell>//-->
      </ui-grid>
      <!-- notice : cliquez sur le btn pour recevoir le devis détaillé -->
      <div class="notice">
        Cliquez sur le bouton ci-dessous pour recevoir le devis détaillé par email.
      </div>
  
      <ui-button raised class="validateBtn" :class="{ 'disabled': quotationStore._cgv == false }" @click="goToDevis()">Recevoir mon devis détaillé</ui-button>
      <div class="spacerForKeyboard"></div>
    </div>
    <div v-else-if="state.step == 'sent'" class="page-content">
      <div class="page-quotation-confirmation">
        <div class="page__quotation__confirmation-page__quotation__subtitle">
          Merci pour votre demande de devis
        </div>
        <div class="page__quotation__confirmation-page__quotation__infos">
          Votre demande de devis à bien été envoyée et vous allez recevoir un email de confirmation dans quelques minutes accompagné de votre devis.<br><br>
          <b>Attention cet email peut arriver dans votre dossier mails indésirables.</b>
          <br>
          <br>
          Si besoin n'hésitez pas à nous contacter : <a href="https://www.deckibois.fr/contact/" target="_blank">https://www.deckibois.fr/contact/</a>
        </div>
      </div>
      <div class="devis-link-section">
        <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/devis.jpg') + ')' }">
          <a href="https://devis.deckibois.fr" class="devis-link-section--link">
            Nouveau devis
          </a>
        </div>
        <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/website.jpg') + ')' }">
          <a href="https://www.deckibois.fr/vente-en-ligne-lames-et-accessoires-terrasse-en-bois/" target="_blank" class="devis-link-section--link">
            Boutique en ligne
          </a>
        </div>
        <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/conseils-de-pose.jpg') + ')', backgroundSize: 'cover' }">
          <a href="https://www.deckibois.fr/conseils-de-pose/" target="_blank" class="devis-link-section--link">
            Conseils de pose
          </a>
        </div>
      </div>
    </div>
    <div v-else @click="emitCustomEvent(this)">
      <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
      <ui-button raised class="validateBtn" :class="$theme.getThemeClass('secondary')" @click="retry()" v-if='state.error == true && state.required == false && state.mailError == false'>Recommencer</ui-button>
      <ui-button raised class="validateBtn" :class="$theme.getThemeClass('secondary')" @click="back('user')" v-if='state.error == true && state.required == true'>Je complète</ui-button>
      <ui-button raised class="validateBtn" :class="$theme.getThemeClass('secondary')" @click="back('email')" v-if='state.error == true && state.required == false && state.mailError == true'>Je corrige</ui-button>
    </div>
  </div>
</template>

<style scoped>
/* Styles généraux */
.page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  margin-bottom: 40px;
}

.page-title h1 {
  color: #2c3e50;
  font-size: 2em;
}

/* Formulaire */
.userForm {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Champs de formulaire */
.labelEmail {
  display: block;
  margin-bottom: 15px;
  color: #2c3e50;
  font-size: 1.2em;
  font-weight: bold;
}

/* Style spécifique pour le champ email */
:deep(.mdc-text-field) {
  width: 100% !important;
  height: 65px !important;
}

:deep(.mdc-text-field--outlined) {
  --mdc-text-field-outlined-idle-border-color: #75756d;
  --mdc-text-field-outlined-hover-border-color: #efab23;
  --mdc-text-field-outlined-focused-border-color: #efab23;
}

:deep(.mdc-text-field .mdc-text-field__input) {
  font-size: 1.1em !important;
  padding: 0 15px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

:deep(.mdc-text-field--outlined .mdc-notched-outline) {
  height: 100% !important;
}

:deep(.mdc-text-field--outlined .mdc-floating-label) {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

:deep(.mdc-text-field--outlined.mdc-text-field--focused .mdc-floating-label,
      .mdc-text-field--outlined.mdc-text-field--label-floating .mdc-floating-label) {
  transform: translateY(-130%) scale(0.75) !important;
}

/* Boutons radio */
.mdc-form-field {
  margin: 10px 5px;
}

.mdc-form-field .mdc-radio {
  display: none;
}

.mdc-form-field label {
  border: 2px solid #75756d;
  color: #75756d;
  padding: 8px 25px !important;
  border-radius: 25px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.mdc-form-field label.selected {
  border: 2px solid #efab23;
  background-color: #efab23;
  color: white;
  font-weight: bold;
}

/* Grille pour les champs */
.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  margin: 20px 0;
}

/* Zone de texte */
textarea {
  width: 100%;
  height: 120px;
  padding: 15px;
  border: 1px solid #75756d;
  border-radius: 8px;
  margin: 10px 0;
  font-family: inherit;
  resize: vertical;
}

/* Checkbox CGV */
.checkbox-field-cgv {
  margin: 25px 0;
}

.checkbox-field-cgv .mdc-form-field {
  align-items: flex-start;
}

.checkbox-field label {
  border: none !important;
  text-align: left;
  padding-left: 10px !important;
}

/* Boutons de navigation */
.nav {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* notice */
.notice {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #2c3e50;
}

.validateBtn {
  width: 100%!important;
  max-width: 340px;
  margin-top: 20px !important;
  background-color: #efab23 !important;
  color: white !important;
  padding: 25px !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
}

.validateBtn:hover:not(.disabled) {
  background-color: #d99b1f !important;
  transform: translateY(-2px);
}

.validateBtn.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Message de confirmation */
.page-quotation-confirmation {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.page__quotation__confirmation-page__quotation__subtitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
}

.page__quotation__confirmation-page__quotation__infos {
  line-height: 1.6;
}

/* Section des liens */
.devis-link-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

.devis-link-section .col {
  flex: 1;
  min-width: 300px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.devis-link-section--link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}

.devis-link-section--link:hover {
  background-color: rgba(239, 171, 35, 0.8);
  font-size: 1.6em;
}

/* Responsive */
@media (max-width: 1024px) {
  .page-content {
    padding: 15px;
  }

  .userForm {
    padding: 20px;
  }

  .col-2 {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .devis-link-section .col {
    min-width: 100%;
  }

  .spacerForKeyboard {
    height: 250px;
  }

  .mdc-form-field {
    margin: 5px;
  }

  .mdc-form-field label {
    padding: 6px 15px !important;
    font-size: 0.9em;
  }
}

/* Messages d'erreur */
.required {
  color: #dc3545;
  font-weight: bold;
}

/* Loading state */
.loading-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
</style>